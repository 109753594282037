import React, { Component } from 'react';
import '../StandardPage/StandardPage.css';
import '../SubmittedProjects/SubmittedProjects';
import MetaTags from 'react-meta-tags';
import { ongoingProjectsService } from '../../services/OngoingProjectsService';
import ReactPaginate from 'react-paginate';
import '../StandardPage/StandardPage.css';
import FormControl from '@material-ui/core/FormControl';
import NativeSelect from '@material-ui/core/NativeSelect';
import archiveJson from '../../resources/archiveYears.json';

class OngoingProjects extends Component {
  constructor(props) {
    super(props);

    this.state = {
      ongoingProjects: [],
      pagination: {},
      searchProject: {
        title: '',
        archiveYear: 2025
      },
      archiveYears: archiveJson
    };
  }

  componentWillMount() {
    ongoingProjectsService
      .getAll()
      .then(jsonResponse => {
        this.setOngoingProjectsState(jsonResponse);
      })
      .catch(error => console.log(error));
  }

  handlePageClick = selectedPage => {
    this.search(selectedPage.selected + 1);
  };

  setOngoingProjectsState = response => {
    let ongoingProjects = { ...this.state.ongoingProjects };
    let pagination = { ...this.state.pagination };
    ongoingProjects = response.ongoing_projects;
    pagination = response.pagination;

    this.setState({ ongoingProjects });
    this.setState({ pagination });
  };
  updateField = event => {
    const field = event.target.name;
    const searchProject = this.state.searchProject;
    searchProject[field] = event.target.value;

    this.setState(
      {
        searchProject
      },
      () => {
        if (field === 'archiveYear') {
          this.search();
        }
      }
    );
  };

  search = (pageNumber = 1) => {
    ongoingProjectsService
      .search(this.state.searchProject, pageNumber)
      .then(jsonResponse => {
        this.setOngoingProjectsState(jsonResponse);
      })
      .catch(error => console.log(error));
  };
  render() {
    return (
      <div className="standard">
        <MetaTags>
          <title>Proiecte În Desfășurare</title>
          <meta
            name="description"
            content="Proiecte In Desfasurare Vicovu de Jos. Aici sunt prezentate proiectele in desfasurare din Vicovu de Jos."
          />
          <meta
            name="keywords"
            content="primaria Vicovu de Jos, Vicovu de Jos, institutii si insitutii subordonate, stimulente educationale si insertie, proiecte cu finantare externa, certificate de urbanism, rapoarte de activitate, publicatii de casatorie, regulament si comisii, proiecte de hotarari, indemnizatii crestere copil, procese verbale sedinte, transparenta venituri salariale, starea civila, asistenta sociala, consiliul local, interes public, hotarari adoptate, inregistrare casatorie, proiecte depuse, Suceava, Bucovina, Sofia Vicoveanca, trasee montane, achizitie, achizitii, registrul agricol, anunturi, nastere, acte nastere, proiecte finalizate, autorizatii de construire, evenimente, informatii financiare, ajutoare incalzire, jurist, conducere, consilieri locali, dispozitiile primarului, inregistrarea casatoriei, proiecte in desfasurare, plan urbanistic general, ajutor social"
          />
          <meta property="og:title" content="Proiecte In Desfasurare" />
        </MetaTags>
        <div className="standard__first-section">
          <div className="standard__title-box">
            <h4 className="standard__generic">Proiecte > </h4>
            <h4 className="standard__generic">Proiecte în desfășurare</h4>
          </div>
          <h2 className="standard__main-title">Proiecte în desfășurare</h2>
        </div>

        <div className="standard__projects">
          <div className="standard__input-box standard__mp-input-box">
            <input
              className="standard__input standard__mp-input"
              label="Cautare Document"
              placeholder="Căutare Document"
              name="title"
              ovalue={this.state.searchProject.title}
              onChange={this.updateField}
            />
            <img
              src="/images/icons/search.svg"
              className="standard__search-icon mariage-publications__search-icon"
              alt="search-icon"
              onClick={() => this.search()}
            />
            <div className="standard__input-box mariage-publications__input-box lead-page__input-box">
              <FormControl>
                <NativeSelect
                  value={this.state.searchProject.archiveYear}
                  onChange={this.updateField}
                  inputProps={{
                    name: 'archiveYear'
                  }}
                >
                  {this.state.archiveYears.map(archive => (
                    <option value={archive.value}>{archive.name}</option>
                  ))}
                </NativeSelect>
              </FormControl>
            </div>
          </div>
          {this.state.ongoingProjects.length > 0 ? (
            <div
              className={
                'standard__projects__wrapper ' +
                (this.state.ongoingProjects.length <= 2
                  ? 'standard__projects-wrapper-few'
                  : '')
              }
            >
              {this.state.ongoingProjects.map(project => (
                <div className="standard__individual-project" key={project.id}>
                  <div className="standard__project-image-info ">
                    {project.picture && project.picture.url !== null ? (
                      <img
                        className="standard__project-image"
                        src={`${process.env.REACT_APP_API_URL}${project.picture.url}`}
                        alt="Project"
                      />
                    ) : (
                      <img
                        src="/images/generalPresentation/project.jpeg"
                        className="standard__project-image"
                        alt="project"
                      />
                    )}
                    <div className="standard__project-info ">
                      <div className="standard__project-value-box">
                        <h4 className="standard__project-value">
                          {project.value} LEI
                        </h4>
                      </div>
                      <div className="standard__project-date-box">
                        <h5 className="standard__project-date">
                          {project.start_date} - {project.end_date}
                        </h5>
                      </div>
                    </div>
                  </div>
                  <h3 className="standard__project-title">{project.title}</h3>
                  <h4 className="standard__project-main-description">
                    {project.description}
                  </h4>
                  <h4 className="standard__see-project">
                    Vezi Detalii Proiect
                  </h4>

                  {project.document && project.document.url !== null && (
                    <button
                      className="standard__button standard__project-button"
                      onClick={() =>
                        window.open(
                          `${process.env.REACT_APP_API_URL}${project.document.url}`
                        )
                      }
                      variant="contained"
                      size="small"
                      color="primary"
                    >
                      <h4 className="standard__button-writing">
                        {' '}
                        Vezi Document Proiect
                      </h4>
                    </button>
                  )}
                </div>
              ))}
            </div>
          ) : (
            <div className="standard__no-projects-box">
              <p className="standard__no-projects">
                Nu sunt proiecte în desfășurare momentan.
              </p>
            </div>
          )}
        </div>
        {this.state.pagination.last_page > 1 && (
          <ReactPaginate
            previousLabel={'<'}
            nextLabel={'>'}
            breakLabel={'...'}
            breakClassName={'break-me'}
            pageCount={this.state.pagination.last_page}
            marginPagesDisplayed={1}
            pageRangeDisplayed={2}
            onPageChange={this.handlePageClick}
            containerClassName={'pagination'}
            subContainerClassName={'pages pagination'}
            activeClassName={'active'}
          />
        )}
      </div>
    );
  }
}

export default OngoingProjects;
