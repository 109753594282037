import React, { Component } from 'react';
import '../StandardPage/StandardPage.css';
import MetaTags from 'react-meta-tags';
import { nonrefundableFundsService } from '../../services/NonrefundableFundsService';
import FormControl from '@material-ui/core/FormControl';
import NativeSelect from '@material-ui/core/NativeSelect';
import archiveJson from '../../resources/archiveYears.json';

class NonrefundableFondsSituation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      documents: [],
      pagination: '',
      searchFund: {
        title: '',
        archiveYear: '2025'
      },
      archiveYears: archiveJson
    };
  }
  componentWillMount() {
    nonrefundableFundsService
      .getAll()
      .then(jsonResponse => {
        console.log(jsonResponse);
        this.SetFondsState(jsonResponse);
      })
      .catch(error => console.log(error));
  }
  SetFondsState = response => {
    let documents = { ...this.state.documents };
    let pagination = { ...this.state.pagination };
    documents = response.annual_funds_programs;
    pagination = response.pagination;

    this.setState({ documents });
    this.setState({ pagination });

    console.log(this.state.documents);
  };
  updateField = event => {
    const field = event.target.name;
    const searchFund = this.state.searchFund;
    searchFund[field] = event.target.value;

    this.setState(
      {
        searchFund
      },
      () => {
        if (field === 'archiveYear') {
          this.search();
        }
      }
    );
  };

  search = (pageNumber = 1) => {
    nonrefundableFundsService
      .search(this.state.searchFund, pageNumber)
      .then(jsonResponse => {
        this.SetFondsState(jsonResponse);
      })
      .catch(error => console.log(error));
  };

  render() {
    return (
      <div className="standard">
        <MetaTags>
          <title>Buget</title>
          <meta
            name="description"
            content="Situația anuală a finanțărilor nerambursabile acordate persoanelor cu scop nepatrimonial."
          />
          <meta
            name="keywords"
            content="primaria Vicovu de Sus, Vicovu de Sus, institutii si insitutii subordonate, stimulente educationale si insertie, proiecte cu finantare externa, certificate de urbanism, rapoarte de activitate, publicatii de casatorie, regulament si comisii, proiecte de hotarari, indemnizatii crestere copil, procese verbale sedinte, transparenta venituri salariale, starea civila, asistenta sociala, consiliul local, interes public, hotarari adoptate, inregistrare casatorie, proiecte depuse, Suceava, Bucovina, trasee montane, achizitie, achizitii, registrul agricol, anunturi, nastere, acte nastere, proiecte finalizate, autorizatii de construire, evenimente, informatii financiare, ajutoare incalzire, jurist, conducere, consilieri locali, dispozitiile primarului, inregistrarea casatoriei, proiecte in desfasurare, plan urbanistic general, ajutor social"
          />
          <meta property="og:title" content="Transparenta Venituri Salariale" />
        </MetaTags>
        <div className="standard__first-section">
          <div className="standard__title-box">
            <h4 className="standard__generic">Buget > </h4>
            <h4 className="standard__generic">
              Situație anuală a finanțărilor nerambursabile
            </h4>
          </div>
          <h2 className="standard__main-title">
            Situația anuală a finanțărilor nerambursabile acordate persoanelor
            cu scop nepatrimonial
          </h2>
        </div>
        <div className="standard__wrapper">
          <div className="standard__input-box standard__mp-input-box">
            <input
              className="standard__input standard__mp-input"
              label="Cautare Document"
              placeholder="Căutare Document"
              name="title"
              ovalue={this.state.searchFund.title}
              onChange={this.updateField}
            />
            <img
              src="/images/icons/search.svg"
              className="standard__search-icon mariage-publications__search-icon"
              alt="search-icon"
              onClick={() => this.search()}
            />
            <div className="standard__input-box mariage-publications__input-box lead-page__input-box">
              <FormControl>
                <NativeSelect
                  value={this.state.searchFund.archiveYear}
                  onChange={this.updateField}
                  inputProps={{
                    name: 'archiveYear'
                  }}
                >
                  {this.state.archiveYears.map(archive => (
                    <option value={archive.value}>{archive.name}</option>
                  ))}
                </NativeSelect>
              </FormControl>
            </div>
          </div>
          <div className="standard__cards standard__projects-wrapper-few">
            {this.state.documents && this.state.documents.length > 0 ? (
              this.state.documents.map(document => (
                <div
                  key={document.id}
                  className="standard__individual-card sa-title"
                >
                  <h3 className="standard__card-title">{document.title}</h3>
                  <h5 className="standard__card-date">{document.add_date}</h5>
                  <h4
                    className="standard__see-project"
                    onClick={() =>
                      window.open(
                        `${process.env.REACT_APP_API_URL}${
                          document.document.url
                        }`
                      )
                    }
                  >
                    Vizualizează Document
                  </h4>
                  <img
                    src="/images/icons/file.svg"
                    className="standard__card-img"
                    alt="file"
                    onClick={() =>
                      window.open(
                        `${process.env.REACT_APP_API_URL}${
                          document.document.url
                        }`
                      )
                    }
                  />
                </div>
              ))
            ) : (
              <div className="standard__no-projects-box">
                <p className="standard__no-projects">
                  Informații in curs de actualizare.
                </p>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default NonrefundableFondsSituation;
